import React, { useEffect, useState, useContext } from "react";
import UserContext from "../../context/UserContext";
import axios from "axios";

const UserTableWithFilters = () => {
    const [history, setHistory] = useState([]); // Datos originales
    const [filteredHistory, setFilteredHistory] = useState([]); // Datos filtrados
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState(""); // Estado para el término de búsqueda
    const itemsPerPage = 20;
    const [error, setError] = useState();
    const { userData } = useContext(UserContext);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredHistory?.slice(indexOfFirstItem, indexOfLastItem) || []; // Cambia a filteredAdvertising

    const totalPages = Math.ceil((filteredHistory?.length || 0) / itemsPerPage); // Basado en filteredAdvertising

    useEffect(() => {
        fetchAllAdvertising();
    }, [userData]);

    const fetchAllAdvertising = async () => {
        try {
            const token = localStorage.getItem("auth-token");
            const user = userData?.user
            const response = await axios.post(
                `${process.env.REACT_APP_BACK_DOMAIN}/history`, user,
                {
                    headers: {
                        "x-auth-token": token,
                        "Content-Type": "application/json",
                    }
                }
            );

            setHistory(response?.data || []);
            setFilteredHistory(response?.data || []); // Inicializa el estado filtrado
        } catch (err) {
            if (err.response?.data?.msg) {
                setError(err.response.data.msg);
            }
        }
    };

    // Función para manejar el filtrado solo por partner
    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        const filtered = history.filter((ad) =>
            ad.email?.toLowerCase().includes(term) // Filtra solo por el campo "partner"
        );
        setFilteredHistory(filtered);
        setCurrentPage(1); // Reinicia a la primera página después de filtrar
    };

    return (
        <div className="container mt-5">
            <div id="oc-alert-container"></div>

            <div
                className="card border-light mb-3"
                style={{ boxShadow: "0 5px 10px 2px rgba(195,192,192,.5)" }}
            >
                <div className="card-header">
                    <h3>Historial Notas</h3>
                </div>
                <div className="card-body">
                    {/* Barra de búsqueda */}
                    {userData.user.role ==="administrador" && 
                    <div className="mb-3">
                        <input
                            type="text"
                            placeholder="Buscar por email..."
                            className="form-control"
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>}

                    {/* Tabla */}
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead className="thead-light">
                                <tr>
                                    <th>email</th>
                                    <th>redactor</th>
                                    <th>tokens</th>
                                    <th>articles</th>
                                    <th>date</th>

                                </tr>
                            </thead>
                            <tbody>
                                {currentItems?.length > 0 ? (
                                    currentItems.map((ad, index) => (
                                        <tr key={index}>
                                            <td>{ad.email}</td>
                                            <td>{ad.redactor}</td>
                                            <td>{ad.tokens}</td>
                                            <td>
                                                <div className="TableCellHistory TableCell-Articulo">{ad.articles}</div>
                                            </td>
                                            <td>{ad.date}</td>

                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="text-center">
                                            No se encontraron resultados.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {/* Controles de paginación */}
                    <div className="pagination-controls">
                        <button
                            className="Button Button_primary"
                            disabled={currentPage === 1}
                            onClick={() => setCurrentPage((prev) => prev - 1)}
                        >
                            &lt;
                        </button>
                        <span>
                            Página {currentPage} de {totalPages}
                        </span>
                        <button
                            className="Button Button_primary"
                            disabled={currentPage === totalPages}
                            onClick={() => setCurrentPage((prev) => prev + 1)}
                        >
                            &gt;
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserTableWithFilters;
